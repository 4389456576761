import React from 'react';
import Gallery from 'react-grid-gallery';
import Layout from '../components/layout';
import SEO from '../components/seo';

export const photos = [

    {
        src: require('../images/cyberseniors/ro-trainings-1.jpg'),
        thumbnail: require('../images/cyberseniors/ro-trainings-1.jpg'),
        caption: 'Trainings on How to Use a Smartphone, Rucăr, Romania - CyberSeniors'
    },

    {
        src: require('../images/cyberseniors/ro-trainings-2.jpg'),
        thumbnail: require('../images/cyberseniors/ro-trainings-2.jpg'),
        caption: 'Trainings on How to use a Smartphone, Rucăr, Romania - CyberSeniors'
    },

    {
        src: require('../images/cyberseniors/ro-trainings-3.jpg'),
        thumbnail: require('../images/cyberseniors/ro-trainings-3.jpg'),
        caption: 'Trainings on How to use a Smartphone, Rucăr, Romania - CyberSeniors'
    },
    
    {
        src: require('../images/cyberseniors/ro-trainings-4.jpg'),
        thumbnail: require('../images/cyberseniors/ro-trainings-4.jpg'),
        caption: 'Trainings on How to use a Smartphone, Rucăr, Romania - CyberSeniors'
    },

    {
        src: require('../images/cyberseniors/ro-trainings-5.jpg'),
        thumbnail: require('../images/cyberseniors/ro-trainings-5.jpg'),
        caption: 'Poster for the training on How to use a Smartphone, Rucăr, Romania - CyberSeniors'
    },

    {
        src: require('../images/forest/final-tpm-1.jpg'),
        thumbnail: require('../images/forest/final-tpm-1.jpg'),
        caption: ''
    },

    {
        src: require('../images/forest/final-tpm-2.jpg'),
        thumbnail: require('../images/forest/final-tpm-2.jpg'),
        caption: ''
    },

    {
        src: require('../images/forest/final-tpm-3.jpg'),
        thumbnail: require('../images/forest/final-tpm-3.jpg'),
        caption: ''
    },

    {
        src: require('../images/cyberseniors/2nd-tpm-1.jpg'),
        thumbnail: require('../images/cyberseniors/2nd-tpm-1.jpg'),
        caption: 'Second Project Meeting, Vienna, Austria - CyberSeniors'
    },

    {
        src: require('../images/cyberseniors/1st-tpm-1.jpg'),
        thumbnail: require('../images/cyberseniors/1st-tpm-1.jpg'),
        caption: 'First Project Meeting, online - CyberSeniors'
    },

    {
        src: require('../images/value/ro-workshops-1.jpg'),
        thumbnail: require('../images/value/ro-workshops-1.jpg'),
        caption: 'Solidarity and Community Building Workshops, Rucăr, Romania - valUE'
    },

    {
        src: require('../images/value/ro-workshops-2.jpg'),
        thumbnail: require('../images/value/ro-workshops-2.jpg'),
        caption: 'Solidarity and Community Building Workshops, Rucăr, Romania - valUE'
    },

    {
        src: require('../images/value/ro-workshops-3.jpg'),
        thumbnail: require('../images/value/ro-workshops-3.jpg'),
        caption: 'Solidarity and Community Building Workshops, Rucăr, Romania - valUE'
    },

    {
        src: require('../images/value/ro-workshops-4.jpg'),
        thumbnail: require('../images/value/ro-workshops-4.jpg'),
        caption: 'Solidarity and Community Building Workshops, Rucăr, Romania - valUE'
    },

    {
        src: require('../images/value/ro-workshops-5.jpg'),
        thumbnail: require('../images/value/ro-workshops-5.jpg'),
        caption: 'Solidarity and Community Building Workshops, Rucăr, Romania - valUE'
    },

    {
        src: require('../images/value/ro-workshops-6.jpg'),
        thumbnail: require('../images/value/ro-workshops-6.jpg'),
        caption: 'Solidarity and Community Building Workshops, Rucăr, Romania - valUE'
    },
    
    {
        src: require('../images/value/ro-workshops-7.jpg'),
        thumbnail: require('../images/value/ro-workshops-7.jpg'),
        caption: 'Solidarity and Community Building Workshops, Rucăr, Romania - valUE'
    },
    
    {
        src: require('../images/value/ro-workshops-8.jpg'),
        thumbnail: require('../images/value/ro-workshops-8.jpg'),
        caption: 'Solidarity and Community Building Workshops, Rucăr, Romania - valUE'
    },

    {
        src: require('../images/value/ro-workshops-9.jpg'),
        thumbnail: require('../images/value/ro-workshops-9.jpg'),
        caption: 'Solidarity and Community Building Workshops, Rucăr, Romania - valUE'
    },

    {
        src: require('../images/value/ro-workshops-10.jpg'),
        thumbnail: require('../images/value/ro-workshops-10.jpg'),
        caption: 'Solidarity and Community Building Workshops, Rucăr, Romania - valUE'
    },

    {
        src: require('../images/value/ro-workshops-11.jpg'),
        thumbnail: require('../images/value/ro-workshops-11.jpg'),
        caption: 'Solidarity and Community Building Workshops, Rucăr, Romania - valUE'
    },

    {
        src: require('../images/value/solidarity-labs-1.jpg'),
        thumbnail: require('../images/value/solidarity-labs-1.jpg'),
        caption: 'Solidarity Labs, Patatrac Presentation - valUE'
    },

    {
        src: require('../images/value/solidarity-labs-2.jpg'),
        thumbnail: require('../images/value/solidarity-labs-2.jpg'),
        caption: 'Solidarity Labs, Defining the Solidarity Dictionary - valUE'
    },

    {
        src: require('../images/value/solidarity-labs-3.jpg'),
        thumbnail: require('../images/value/solidarity-labs-3.jpg'),
        caption: 'Solidarity Labs, Brainstorming - valUE'
    },

    {
        src: require('../images/value/solidarity-labs-4.jpg'),
        thumbnail: require('../images/value/solidarity-labs-4.jpg'),
        caption: 'Solidarity Labs, SWOT Analysis - valUE'
    },

    {
        src: require('../images/forest/rucar-2.jpg'),
        thumbnail: require('../images/forest/rucar-2.jpg'),
        caption: 'FOREST project dissemination and debates on forestry workers training, in the open, Rucăr Forestry District - FOREST'
    },

    {
        src: require('../images/forest/rucar-1.jpg'),
        thumbnail: require('../images/forest/rucar-1.jpg'),
        caption: 'Dialogue on security at work in case of forestry operators - FOREST'
    },

    {
        src: require('../images/forest/2nd-tpm-1.jpg'),
        thumbnail: require('../images/forest/2nd-tpm-1.jpg'),
        caption: 'Sankt Barbara im Mürztal, Austria - FOREST'
    },

    {
        src: require('../images/forest/2nd-tpm-2.jpg'),
        thumbnail: require('../images/forest/2nd-tpm-2.jpg'),
        caption: 'Sankt Barbara im Mürztal, Austria - FOREST'
    },

    {
        src: require('../images/forest/2nd-tpm-3.jpg'),
        thumbnail: require('../images/forest/2nd-tpm-3.jpg'), 
        caption: 'Sankt Barbara im Mürztal, Austria - FOREST'
    },

    {
        src: require('../images/forest/2nd-tpm-4.jpg'),
        thumbnail: require('../images/forest/2nd-tpm-4.jpg'),
        caption: 'Sankt Barbara im Mürztal, Austria - FOREST' 
    },

    {
        src: require('../images/forest/1st-tpm-1.jpg'),
        thumbnail: require('../images/forest/1st-tpm-1.jpg'),
        caption: 'Legnaro, Italy - FOREST' 
    },

    {
        src: require('../images/forest/1st-tpm-2.jpg'),
        thumbnail: require('../images/forest/1st-tpm-2.jpg'),
        caption: 'Legnaro, Italy - FOREST'  
    },

    {
        src: require('../images/forest/1st-tpm-3.jpg'),
        thumbnail: require('../images/forest/1st-tpm-3.jpg'),
        caption: 'Legnaro, Italy - FOREST' 
    },

    {
        src: require('../images/forest/1st-tpm-4.jpg'),
        thumbnail: require('../images/forest/1st-tpm-4.jpg'),
        caption: 'Legnaro, Italy - FOREST'
    },

    {
        src: require('../images/recov/1.jpg'),
        thumbnail: require('../images/recov/1.jpg'),
        caption: 'Brussels, Belgium - RECOV' 
    },

    {
        src: require('../images/recov/2.jpg'),
        thumbnail: require('../images/recov/2.jpg'),
        caption: 'Brussels, Belgium - RECOV' 
    },

    {
        src: require('../images/recov/3.jpg'),
        thumbnail: require('../images/recov/3.jpg'),
        caption: 'Santorso, Italy - RECOV' 
    },

    {
        src: require('../images/recov/4.jpg'),
        thumbnail: require('../images/recov/4.jpg'),
        caption: 'Skopje, Macedonia - RECOV' 
    },

    {
        src: require('../images/recov/5.jpg'),
        thumbnail: require('../images/recov/5.jpg'),
        caption: 'Lisbon, Portugal - RECOV' 
    },

    {
        src: require('../images/femme/1.jpg'),
        thumbnail: require('../images/femme/1.jpg'),
        caption: 'Larissa, Greece - FEMME' 
    },

    {
        src: require('../images/femme/2.jpg'),
        thumbnail: require('../images/femme/2.jpg'),
        caption: 'Pécs, Hungary - FEMME'
    },

    {
        src: require('../images/femme/3.jpg'),
        thumbnail: require('../images/femme/3.jpg'),
        caption: 'Evora, Portugal - FEMME' 
    },

    {
        src: require('../images/smart-participation/1.jpg'),
        thumbnail: require('../images/smart-participation/1.jpg'),
        caption: 'Rūjiena, Latvia - SMART PARTICIPATION'  
    },

    {
        src: require('../images/smart-participation/2.jpg'),
        thumbnail: require('../images/smart-participation/2.jpg'),
        caption: 'Rūjiena, Latvia - SMART PARTICIPATION'  
    }

]

export default function GalleryPage() {
   
    return(
        <Layout>
            <SEO title = 'Gallery'/>
            <div className = 'font-monserrat text-red-dark text-4xl font-light'>Photos</div>
            <br/>
            <div>
                <Gallery images={photos} margin={5} rowHeight={300} />
            </div>
        </Layout>
    )
}
